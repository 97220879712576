<!--
 * @Author: your name
 * @Date: 2021-11-11 16:23:19
 * @LastEditTime: 2022-07-26 11:05:18
 * @LastEditors: steven 1105624290@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ytjj-mobile/src/views/login/index.vue
-->

<template>
  <div class="login">
    <div class="text" v-if="showTips">
      <div>移动端目前允许教师查看探究资讯和最近三周教案</div>
      <div>其他功能请登录电脑端使用</div>
      <img class="cancal" src="@/assets/img/Layer 23.png" alt="" @click="cancalTips">
    </div>
    <div class="logo">
      <img src="@/assets/img/new_logo.png" alt="" class="logo_img">
    </div>
    <div class="van_input" id="dom">
      <div>
        <van-field @focus="inputFocus()" :left-icon="userIcon" placeholder="请输入用户名" v-model="loginForm.username"></van-field>
      </div>
      <div class="password">
        <van-field :left-icon="passIcon" v-model="loginForm.password" placeholder="请输入密码" type="password"></van-field>
      </div>
      <van-button class="submit" @click="login" :loading="loginLoading" loading-text="登录中...">登录</van-button>
    </div>
    <ChooseClass :sendClass="sendClass" ref="selectClass"></ChooseClass>
  </div>
</template>

<script>
import { frontLoginApi, mobileGetInfoApi } from "@/api/login/index.js";
import { random_string } from "@/utils/utils";
import ChooseClass from "@/components/chooseClass/index"
export default {
  components:{ 
    ChooseClass
  },
  data() {
    return {
      userIcon: require('../../assets/img/Layer 20.png'),
      passIcon: require('../../assets/img/Layer 21.png'),
      loginForm: {
        username: "",
        password: "",
      },
      token:"",
      ishasClass:false,
      classInfo:[],//这是展示选择班级弹框的数据
      errorYear:[],
      errorTips:false,
      loginLoading:false,
      sendClass:[],
      showTips:true
    };
  },
  methods: {
    login() {
      if (!this.loginForm.username.trim()) {
        this.$notify( {type: 'warning', message: '请输入用户名' });
        return;
      }
      if (!this.loginForm.password.trim()) {
        this.$notify( {type: 'warning', message: '请输入密码' });
        return;
      }
      this.ishasClass = false;
      this.classInfo = [];
      this.errorYear = [];
      this.errorTips = false;
      this.loginLoading = true;
      frontLoginApi(
        {
          username: this.loginForm.username.trim(),
          password: this.loginForm.password.trim(),
          type: "00", // 类型
          uuid: random_string(), // 随机生成32位字符串
        }
      ).then((res) => {
        // console.log(res);
        this.token = res.token;
        this.loginLoading = false;
        this.$Cookie.set("token", res.token); //将token存到cookie中
        localStorage.setItem("courseToken", res.token);
        sessionStorage.setItem("courseToken", res.token);
        mobileGetInfoApi().then(res2 => {
          this.$Cookie.set("username", res2.user.userName);
          this.$Cookie.set("password", this.loginForm.password);
          localStorage.setItem("username", res2.user.userName);
          localStorage.setItem("password", this.loginForm.password);
          // console.log(res2)
          // 看roles是否包含teacher 包含的话进行下一步，如果不包含文字提示 目前移动端仅支持教师登录 
          // 下一步分为是否出现选择班级的弹框以及登录失败的弹框
          if(res2.roles.includes("teacher")){
            console.log(res2.GroupLoginResponseVo.schoolLoginCountryResponseVos[0].schoolLoginProvinceResponseVos[0].schoolLoginResponseVos)
            let classData = res2.GroupLoginResponseVo.schoolLoginCountryResponseVos[0].schoolLoginProvinceResponseVos[0].schoolLoginResponseVos
            this.$storeLocal.set("schoolData",classData);//存储学校信息
            classData.forEach((item) => {
              console.log("包含所有学年的数组",item.schoolLoginClassesDetailResponseVos)
              // 先判断学年，再判断当前的学年有没有当前老师任教的班级
              for (const iterator of item.schoolLoginClassesDetailResponseVos) {
                console.log("每个学年的数据",iterator)
                //当前登录时间在学年范围内
                //当前学年逻辑(正常可以登录进去的)
                // 学年重叠逻辑
                if(this.isDuringDate(iterator.year.startTime,iterator.year.endTime)){
                  // console.log("这是符合学年开始结束日期的数据",iterator)
                  if(iterator.calendarYearName === this.$moment().format('YYYY')){
                    this.sendClass = iterator.schoolLoginClassesGradeDetailResponseVos;
                    for(const item2 of iterator.schoolLoginClassesGradeDetailResponseVos){
                      for(const item3 of item2.schoolLoginClassesGradeIsTeacherDetailResponseVos){
                        // console.log("这是底层数据",item3)
                        if(item3.isTeacher === '1'){
                          this.classInfo.push(item3);
                          // this.ishasClass = true;
                        }
                      }
                    }
                    this.ishasClass = this.classInfo.length? true:false;
                    break;
                  }else{
                    //如果当前学年不符合登录日期就取上个学年的
                    this.sendClass = iterator.schoolLoginClassesGradeDetailResponseVos;
                    for(const item2 of iterator.schoolLoginClassesGradeDetailResponseVos){
                      for(const item3 of item2.schoolLoginClassesGradeIsTeacherDetailResponseVos){
                        // console.log("这是底层数据",item3)
                        if(item3.isTeacher === '1'){
                          this.classInfo.push(item3);
                          // this.ishasClass = true;
                        }
                      }
                    }
                    this.ishasClass = this.classInfo.length? true:false;
                    break;
                  }
                }else{
                  //这是无法正常登录进去的
                  // 1.延后提前一周登录逻辑
                  // 2.空白时间逻辑
                  console.log("这是当前登录时间不在学年范围内的数据")
                  let advanceDay = this.$moment(iterator.year.startTime).subtract(7, 'days').format("YYYY-MM-DD")
                  let delayDay = this.$moment(iterator.year.endTime).add(7, 'days').format("YYYY-MM-DD")
                  //提前一周或者延后一周可以登录进去的
                  if(this.isDuringDate(advanceDay,delayDay)){
                    console.log("这是提前一周或者延续一周可以登录进去的")
                    // 如果这边有两个学年同时满足条件取学年大的那个
                    let bigYear = iterator.calendarYearName - this.$moment().format('YYYY');
                    if(bigYear){
                      this.sendClass = iterator.schoolLoginClassesGradeDetailResponseVos;
                      for(const item2 of iterator.schoolLoginClassesGradeDetailResponseVos){
                        for(const item3 of item2.schoolLoginClassesGradeIsTeacherDetailResponseVos){
                          if(item3.isTeacher === '1'){
                            this.classInfo.push(item3);
                          }
                        }
                      }
                      this.ishasClass = this.classInfo.length? true:false;
                      break;
                    }else if(iterator.calendarYearName === this.$moment().format('YYYY')){
                      this.sendClass = iterator.schoolLoginClassesGradeDetailResponseVos;
                      for(const item2 of iterator.schoolLoginClassesGradeDetailResponseVos){
                        for(const item3 of item2.schoolLoginClassesGradeIsTeacherDetailResponseVos){
                          if(item3.isTeacher === '1'){
                            this.classInfo.push(item3);
                          }
                        }
                      }
                      this.ishasClass = this.classInfo.length? true:false
                      break;
                    }else{
                      this.sendClass = iterator.schoolLoginClassesGradeDetailResponseVos;
                      for(const item2 of iterator.schoolLoginClassesGradeDetailResponseVos){
                        for(const item3 of item2.schoolLoginClassesGradeIsTeacherDetailResponseVos){
                          if(item3.isTeacher === '1'){
                            this.classInfo.push(item3);
                          }
                        }
                      }
                      this.ishasClass = this.classInfo.length? true:false;
                      break;
                    }
                  }else{
                    this.errorYear.push(iterator)
                    if(this.errorYear.length === item.schoolLoginClassesDetailResponseVos.length){
                      // this.$notify( {type: 'danger', message: '已经超过本学年登录范围' });
                      this.errorTips = true
                    }
                  }
                }
              }
            })
            //是否出现学年提示(先判断学年再判断班级)
            if(this.errorTips){
              // this.$notify( {type: 'danger', message: '已经超过本学年登录范围' });
              this.$router.push({
                  name: "PD",
              });
            }else{
              // 看是否出现没有班级弹框
              // this.ishasClass?this.$refs.selectClass.show = true:this.$refs.selectClass.noClass = true;
              if(this.ishasClass){
                this.$refs.selectClass.show = true
              }else{
                // console.log("99999")
                this.$router.push({
                    name: "PD",
                });
              }
            }
          }else{
            this.$notify( {type: 'danger', message: '目前移动端仅支持教师登录' });
          }
        })
      }).catch(()=>{
        this.loginLoading = false;
      })
    },
    // 判断当前登陆时间是否在当前学年时间内
    isDuringDate (beginDateStr, endDateStr) {
      var curDate = new Date(),
      beginDate = new Date(beginDateStr),
      endDate = new Date(endDateStr);
      if (curDate >= beginDate && curDate <= endDate) {
      return true;
      }
      return false;
    },
    cancalTips(){
      this.showTips = false;
    },
    inputFocus(){
      var dom=document.getElementById('dom')
      setTimeout(function(){
        dom.scrollIntoView(true);
        dom.scrollIntoViewIfNeeded();
      }, 100);
    }
  },
  created() {
    // console.log(this.$Cookie.get("username"))
    // console.log(this.$Cookie.get("password"))
    if (localStorage.getItem("username") || this.$Cookie.get("username")) {
      this.loginForm.username = localStorage.getItem("username") || this.$Cookie.get("username");
    }
    if (localStorage.getItem("password") || this.$Cookie.get("password")) {
      this.loginForm.password = localStorage.getItem("password") || this.$Cookie.get("password");
    }
  },
  mounted(){
    
  }
};
</script>
<style lang="scss" scoped>
.login{
  height: 100%;
  width: 100%;
  background-image: url("../../assets/img/Layer 21@2x.png");
  background-size: 100% 100%;
  overflow: auto;
  position: relative;

  .text{
    width: 100%;
    height: 124px;
    // text-align: center;
    font-size: 26px;
    color: #606060;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    padding-left: 66px;
    padding-top: 30px;
    background-color: #FFC002;

    .cancal{
      position: absolute;
      width: 30px;
      height: 30px;
      right: 46px;
      top: 46px;
    }
  }

  .logo{
    margin-top: 200px;
    // margin-left: 187px;
    // border: 1px solid red;
    text-align: center;
    .logo_img{
      // width: 296px;
      height: 152px;
      object-fit: full;
    }
  }

  .van_input{
    width: 100%;
    padding: 20px 70px;
    box-sizing: border-box;
    margin-top: 20px;

    .password{
      // margin-top: 58px;
    }

    .submit{
      width: 100%;
      height: 87px;
      line-height: 87px;
      text-align: center;
      background-color: #4DB3B3;
      font-size: 32px;
      font-weight: bold;
      color: #FFFFFF;
      margin-top: 90px;
      border: 1px solid #4DB3B3;
    }

    /deep/.van-cell{
      background-color: transparent;
      border-bottom: 2px solid #828282;
      // padding: 0 0;
      padding: 50px 0 0 0;

      .van-field__control{
        padding-left: 20px;
      }

      .van-icon__image{
        width: 39px;
        height: 43px;
        padding-left: 5px;
      }
      // .van-field__label{
      //   color: #828282;
      //   width: 100px;
      //   margin-right: 0px;
      // }
      .van-field__value{
        // border-bottom: 2px solid #828282;
        // padding-bottom: 10px;
        font-size: 38px;
      }
    }
  }
}
</style>